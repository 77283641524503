/**
 * @typedef {Object} phoneMappingRingpool
 * @property {string} url - URL Endesa donde existe el mapeo de numeros 
 * @property {string} defaultPhoneUnavailable - Telefono disponible para dicha url 
 * @property {string} defaultPhone - Telefono por defecto 
 */
export const phoneMappingRingpool = [
    {
        url: ".com/zurich",
        defaultPhoneUnavailable: "900906975",
        defaultPhone: "900905047"
    },
    {
        url: "/gas/cat",
        defaultPhone: "900906911"
    },
    {
        url: "/luz/cat",
        defaultPhone: "900906911"
    },
    {
        url: "gas/dual",
        defaultPhone: "900906912"
    },
    {
        url: "/luz/2residencia",
        defaultPhone: "900906912"
    },
    {
        url: "/luz/dual",
        defaultPhone: "900906912"
    },
    {
        url: "ahorroendesa.com/formidable",
        defaultPhoneUnavailable: "900906975",
        defaultPhone: "900906975"
    },
    {
        url: "endesatarifasluzygas.com/luzgas/en",
        defaultPhoneUnavailable: "900905080",
        defaultPhone: "900905080"
    },
    {
        url: "endesatarifasluzygas.com/d2c1",
        defaultPhoneUnavailable: "919101131",
        defaultPhone: "919101131"
    },
    {
        url: "endesatarifasluzygas.com/d2c2",
        defaultPhoneUnavailable: "919101126",
        defaultPhone: "919101126"
    },
    {
        url: "endesatarifasluzygas.com/d2c3",
        defaultPhoneUnavailable: "919101127",
        defaultPhone: "919101127"
    },
    {
        url: "endesatarifasluzygas.com/d2c1c",
        defaultPhoneUnavailable: "919101128",
        defaultPhone: "919101128"
    },
    {
        url: "endesatarifasluzygas.com/d2c2c",
        defaultPhoneUnavailable: "919101129",
        defaultPhone: "919101129"
    },
    {
        url: "endesatarifasluzygas.com/d2c3c",
        defaultPhoneUnavailable: "919101130",
        defaultPhone: "919101130"
    }
];