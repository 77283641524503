/**
 * Variables Globales para Repsol
 */

export const Globals = {
    /*Id Tag D1C*/
    idTagD1C: "29842f94d414949bf95fb2e6109142cfef1fb2a78114c2c536a36bf5a65b953a97c504a3b38b6c6f213d71b7809a24376b2b7c857e3b7cbca01c77587ca154a8cf118aad9da1a6e2545e6baef324f22eb9231dcbb6015c14ea93891f6f6b0a1b85ed0ce4a7cdc031b4e2672bada861c0",
    /*Variables globales D3A whatsapp*/
    D3A: {
        clientId: "7d4c1ef5-9a05-4aa2-90dc-617bc829d6d2",
        campaignId: "076205ce-e72b-4641-aee9-c776076f7ed9"
    }
}