
/**
 * Funcion que dado un array @param medios (los nombres de utms que se van a recoger) recogemos dichos nombres los comprobamos si vienen en la url y los seteamos como etiquetas en Delio
 */
export const existDFI = function () {
    if (typeof DFI === "undefined") {
        globalThis.DFI = {};
    }
  

    if (typeof digitalData["cookies_accepted_analisis"] === 'undefined' && typeof digitalData["cookies_accepted_publicidad"] === 'undefined') {
        digitalData["cookies_accepted_analisis"] = "false";
        digitalData["cookies_accepted_publicidad"] = "false";
    }
    digitalData.user_agent = encodeURIComponent(navigator.userAgent);

    var medios = ['sem', 'dis', 'sop', 'eml', 'afl', 'agg', 'int', 'bcb', 'wpu', 'ome', 'app', 'vau'];
    medios.forEach(function (medio) {
        var etiquetas = DFI.getUrlVar(medio);
        if (etiquetas) {
            etiquetas.split(':').forEach(function (valor, i) {
                if (valor) {
                    var keys = ['Fuente de Tráfico', 'Proveedor', 'Campaña', 'visit_46'];
                    if (i <= 3) {
                        digitalData[keys[i]] = valor;
                    } else {
                        digitalData['visit_' + (i - 4 + 20)] = valor;
                    }
                }
                digitalData['visit_42'] = medio;
            });
        }
    });
};