let existAbtastyInDigitaldataCount = 0;
let execAbtastyToDo = true;
let existAbtastyInDigitaldata = null;

function handleAbtasty() {
  clearInterval(existAbtastyInDigitaldata);

  if (typeof digitalData.experiencia_abtasty === "object" && typeof digitalData.experiencia_abtasty.abtasty === "string") {
    digitalData.variable_abtasty = digitalData.experiencia_abtasty.abtasty;
  } else {
    digitalData.variable_abtasty = "No_asignada";
  }

  execAbtastyToDo = false;

  if (typeof DFI.updateVarsInDelio === "function") {
    DFI.updateVarsInDelio();
  }
}

function checkAbtastyVariation() {
  if (typeof digitalData.abtasty === "string" && execAbtastyToDo) {
    handleAbtasty();
    return true;
  }

  if (typeof window.ABTasty !== "undefined" && execAbtastyToDo) {
    const arrAbtasty = Object.keys(window.ABTasty.getTestsOnPage()).map((key) => [Number(key), window.ABTasty.getTestsOnPage()[key]]);
    if (arrAbtasty.length > 0 && typeof arrAbtasty[0][1].variationName !== "undefined") {
      handleAbtasty();
      return true;
    }
  }

  if (existAbtastyInDigitaldataCount > 10 && execAbtastyToDo) {
    digitalData.variable_abtasty = "No_asignada";
    execAbtastyToDo = false;
    if (typeof DFI.updateVarsInDelio === "function") {
      DFI.updateVarsInDelio();
    }
    return true;
  }

  return false;
}

function checkAbtastyInterval() {
  existAbtastyInDigitaldataCount += 1;

  if (checkAbtastyVariation()) {
    clearInterval(existAbtastyInDigitaldata);
  }

  if (existAbtastyInDigitaldataCount > 10 && execAbtastyToDo) {
    clearInterval(existAbtastyInDigitaldata);
    digitalData.variable_abtasty = "No_asignada";
    execAbtastyToDo = false;
    if (typeof DFI.updateVarsInDelio === "function") {
      DFI.updateVarsInDelio();
    }
  }
}

export function checkAbtasty() {
  if (window.location.pathname === '/asesoramiento-gratuito/test-a/') {
    existAbtastyInDigitaldata = setInterval(checkAbtastyInterval, 350);
  } else {
    if (location.hostname !== "localhost") {
      existAbtastyInDigitaldata = setInterval(checkAbtastyInterval, 350);
    }
  }
}