import { Globals } from "../../../../globals/variables";
import { loadLib } from "../../../../utils/utils";
import { phoneMappingRingpool } from "./assets/phoneMapping";

/***
* Funcion que formatea una cadena string utilizando una máscara mask especificada. Divide mask en patrones y extrae secciones correspondientes de string según la longitud de cada patrón. Luego, devuelve la cadena formateada sin espacios en blanco adicionales al final.
* 
*/
export const buildMaskString = function (string, mask) {
    mask = mask || "##########################################################################################################";
    var formatedString = ""
        , lastPosition = 0
        , pattern = mask.split(" ");
    for (var index in pattern)
        formatedString += string.substr(lastPosition, pattern[index].length) + " ",
            lastPosition += pattern[index].length;
    return formatedString = formatedString.replace(/\s+$/gi, "")
}


/**
 *  Funcion que pinta el telefono por defecto segun el mapeado de urls en caso de que la url no este contemplada en el mapeado pinta el telefono por defecto
 * @returns {Object} defaultPhone,defaultPhoneUnavailable
 */

export const defaultPhoneRingpool = function () {


    let defaultPhone = "900906913";
    let defaultPhoneUnavailable = "900905048";

    const phoneMapping=phoneMappingRingpool

    for (const entry of phoneMapping) {
        if (window.location.href.includes(entry.url)) {
            defaultPhoneUnavailable = entry.defaultPhoneUnavailable || defaultPhoneUnavailable;
            defaultPhone = entry.defaultPhone || defaultPhone;
            break;
        }
    }

    const defaultPhoneUnavailableElements = document.getElementsByClassName("drp-phone");
    for (let i = 0; i < defaultPhoneUnavailableElements.length; i++) {
        const element = defaultPhoneUnavailableElements[i];
        element.innerHTML = buildMaskString(defaultPhoneUnavailable, "### ### ###");
        if (element.hasAttribute("href") && element.getAttribute("href").indexOf("tel:") > -1) {
            element.href = "tel:" + defaultPhoneUnavailable;
        }
    }

    return {
        defaultPhone,
        defaultPhoneUnavailable
    };
};

/**
 * @function Funcion que crea un div y añade las clases del ringpool  e ids y los inserta el el body
 * @returns Div 
 */
export const  createDivRingpool=function(){
    const newElement = document.createElement("div");
    newElement.setAttribute('id', "w_ringpool");
    newElement.setAttribute('class', "drp-phone wal-drp-phone");
    newElement.setAttribute('style', "display:none;");
    document.body.appendChild(newElement);
}
/*
 * Funcion para agregar etiquetas al Digital Data que luego seran recogidas por Delio 
 */

function addLabelsMapping() {
    digitalData.atencion_cualificado = typeof digitalData.audiences === "undefined" ? "no" : digitalData.audiences;
}

/**
 * @function funcion asincrona que llama a la funcion addLabelsMapping y loadLib 
 * @returns script 
 */
export const loadD1C= async function(){ 
    addLabelsMapping()
    globalThis.idTagDelio = Globals.idTagD1C;
    return loadLib("https://t.womtp.com/slider/c/delio-lm/dfi/init.js",Globals.idTagD1C)
            
}
      
          
    