import { getUrlVar } from "../../utils/utils";
import {checkAbtasty} from "../DELIO/js/checkAbtasty"
import { addClickEventToTabLinks } from "./js/addEventAhorro";
import { checkExpTarget } from "./js/checkExpTarget";

import { existDFI } from "./js/existDFI";
import { checkMask } from "./js/ringpool/checkMask";
import { explodeParamCookie, storeCookiesacceptance, watchCookie } from "./js/ringpool/observerDigitalData";
import { buildMaskString, createDivRingpool, defaultPhoneRingpool } from "./js/ringpool/ringpool";
import { updateVarsInDelio } from "./js/updateVarsInDelio";

export const DFI_client_functions= {
    buildMaskString: buildMaskString,
    defaultPhoneRingpool:defaultPhoneRingpool,
    defaultPhone:defaultPhoneRingpool().defaultPhone,
    defaultPhoneUnavailable:defaultPhoneRingpool().defaultPhoneUnavailable
};


export const DFI_functions={
    storeCookiesacceptance:storeCookiesacceptance,
    getUrlVar:getUrlVar,
    updateVarsInDelio:updateVarsInDelio,
    checkAbtasty:checkAbtasty,
    checkExpTarget:checkExpTarget  
}
  
  
export const loadDelio=function(){

    if (window.location.search.indexOf("sms=sms") > -1) {
        globalThis.DFI_client.LandingCustomVars = {};
        globalThis.DFI_client.LandingCustomVars.EDeclaratives = {};
        globalThis.DFI_client.LandingCustomVars.EDeclaratives.sms = "sms";
    }
    watchCookie("cookies_accepted","analisis")
    createDivRingpool()
    existDFI()
    checkAbtasty()
  
    addClickEventToTabLinks()
    checkMask()
    
}


