import { loadWhatsapp } from "../dist/js/D3A/whatsapp";
import { DFI_client_functions, DFI_functions, loadDelio} from "./js/DELIO/delio";
import { loadD1C } from "./js/DELIO/js/ringpool/ringpool";


if(globalThis.location.hostname=="localhost" || globalThis.location.hostname=="t.womtp.com" ){
  let cookieValue = JSON.stringify({"analisis":"true","publicidad":"true"});
  setTimeout(() => {
    document.cookie = "cookies_accepted=" + cookieValue + "; path=/";
 
  }, 3000);
}

sessionStorage.setItem('debugDET', '');
sessionStorage.setItem('debugPreDET', 'on');
if (typeof statusDFI != "object") {
     var statusDFI = {};
}

statusDFI.loadPlatformsIds = false;

/**
 * Global DFI client object: se encuentra las funciones de DFI_client como las que se usan en este script , de manera globals
 * @global
 * @namespace
 * @type {Object}
 */
globalThis.DFI_client = globalThis.DFI_client ? {...globalThis.DFI_client, ...DFI_client_functions} : DFI_client_functions;

/**
 * Global DFI object: se encuentra todas las funciones de este scripts
 * @global
 * @namespace
 * @type {Object}
 */
globalThis.DFI = globalThis.DFI ? {...globalThis.DFI, ...DFI_functions} : DFI_functions;

/**
 * Global digitalData object : el digital data es un objeto que setea Endesa en el cual estan todas las etiquetas que luego seran volcadas a Delio
 * @global
 * @type {Object}
 */
globalThis.digitalData = typeof digitalData !== "undefined" ? {...digitalData} : {};

/**
 * Funcion que carga las librerias de DELIO ONE CLICK y D3A WHATSAPP. Tambien setea el idVisitor y el leadId que sera usado  y recogido por D3A  para el tracking
 */

async function loadLibraries() {
    try {
      await loadD1C()
    globalThis.idVisitor = globalThis.Piwik.getAsyncTracker().getVisitorId();
    globalThis.leadId = globalThis.Piwik.getAsyncTracker().getVisitorId();
    
    } catch (error) {
      console.error("Ocurrió un error:", error);
    }
  }


  loadDelio()  
  loadLibraries();



  
