/**
 *
 * @param { String } FILE_URL Url of script to load
 * @param { Boolean } async If true, the script is loaded asynchronously
 * @param { String } type Type of the script tag
 * @returns Promise
 */
export const loadScript = (
    FILE_URL,
    async = true,
    type = "text/javascript"
  ) => {
    return new Promise((resolve, reject) => {
      const scriptElement = document.createElement("script");
  
      scriptElement.type = type;
      scriptElement.async = async;
      scriptElement.src = FILE_URL;
  
      scriptElement.onload = resolve;
      scriptElement.onerror = reject;
      document.head.appendChild(scriptElement);
    });
  };
  
  /**
   * Sustituya a loadScriptAsync o loadScript
   * @param {String} url La url de la librería a cargar
   * @returns Una promesa de cada carga de la librería
   */
  export function loadLib(url, idTag) {
    return new Promise((resolve, reject) => {
      globalThis.idTagDelio = idTag
      const script = document.createElement("script");
      script.src = url;
      script.async = true;
      script.onload = () => {
      let timeKill=setTimeout(()=>{
          clearTimeout(timeKill)
          resolve("carga D1C"); // Carga
        },1200)
      };
      script.onerror = reject; // No carga
      document.head.appendChild(script);
    });
  }
  
  export function loadLibTimeOut(url, idTag) {
    return new Promise((resolve, reject) => {
      globalThis.idTagDelio = idTag
      const script = document.createElement("script");
      script.src = url;
      script.async = true;
      script.onload = () => {
        setTimeout(()=>{
          resolve("carga whatsapp"); // Carga
        },1000)
  
      };
      script.onerror = reject; // No carga
      document.head.appendChild(script);
    });
  }
  
  /**
   * 
   * @param {Array} libs Array de objetos con las librerías a cargar
   */
  export async function loadAllLibs(libs) {
  
  
    try {
      console.log('entro aqui por')
      const promises = libs.map(lib => loadLib(lib.url))
      await Promise.all(promises)
    } catch (error) {
      console.error(errorWalmeric)
    }
  
  }
  
  
  /**
   *
   * @param { Array } arrayPromises Array of Promises to resolve
   * @returns response
   */
  
  export const loadMultiScript = async (arrayPromises) => {
    try {
      const promises = await Promise.all(arrayPromises);
      return promises;
    } catch (error) {
      console.log(error);
    }
  };
  

  export const getUrlVar = function(variable, url) {
		var href = url || window.location.search;
		var i, pair;
		var query = href.split("?")[1];
		var vars = query ? query.split("&") : [];

		for (let i = 0; i < vars.length; i++) {
			pair = vars[i].split("=");
			if (pair[0] == variable && pair[1] !== "") return pair[1];
		}

		return "";
	};

  export function loadLibD3AWhatsappButton(url, idTag) {
    return new Promise((resolve, reject) => {
      globalThis.idTagDelio = idTag // Asegúrate de que 'idTag' esté definido en alguna parte del código
      const script = document.createElement('script')
      script.src = url // Asegúrate de que 'url' esté definido y sea la URL del script que deseas cargar
      script.async = true
  
      // Esta función intenta insertar el widget y deshabilitar el botón
      const attemptToDisableButton = () => {
        const widget = document.querySelector('#walmeric-whatsapp-widget')
       
        const containerWhatsapp = document.querySelector('#whatsappWalmeric')
  
        // Verifica si ambos elementos existen
        if (!widget) {
          reject(new Error("No se encontró el widget 'walmeric-whatsapp-widget'"))
          return
        }
        if (!containerWhatsapp) {
          reject(new Error("No se encontró el contenedor 'whatsappWalmeric'"))
          return
        }
       
  
        // Mueve el widget al contenedor y deshabilita el botón
        containerWhatsapp.appendChild(widget)
      
        resolve() // Se resuelve la promesa con éxito
      }
  
      script.onload = async (button) => {
        attemptToDisableButton()
        

      }
  
      script.onerror = () => {
        reject(new Error('El script no se pudo cargar'))
      }
  
      document.head.appendChild(script)
    })
  }

  export function loadLibD3AWhatsappButtonMobile(url, idTag) {
    return new Promise((resolve, reject) => {
      globalThis.idTagDelio = idTag // Asegúrate de que 'idTag' esté definido en alguna parte del código
      const script = document.createElement('script')
      script.src = url // Asegúrate de que 'url' esté definido y sea la URL del script que deseas cargar
      script.async = true
  
      // Esta función intenta insertar el widget y deshabilitar el botón
      const attemptToDisableButton = () => {
        const widget = document.querySelector('#walmeric-whatsapp-widget')
       
        const containerWhatsapp = document.querySelector('#whatsappWalmericMobile')
  
        // Verifica si ambos elementos existen
        if (!widget) {
          reject(new Error("No se encontró el widget 'walmeric-whatsapp-widget'"))
          return
        }
        if (!containerWhatsapp) {
          reject(new Error("No se encontró el contenedor 'whatsappWalmericMobile'"))
          return
        }
       
  
        // Mueve el widget al contenedor y deshabilita el botón
        containerWhatsapp.appendChild(widget)
      
        resolve() // Se resuelve la promesa con éxito
      }
  
      script.onload = async (button) => {
        attemptToDisableButton()
        

      }
  
      script.onerror = () => {
        reject(new Error('El script no se pudo cargar'))
      }
  
      document.head.appendChild(script)
    })
  }




  export const getDevice = () => {
    return new Promise((resolve, reject) => {
      const agenteUsuario = navigator.userAgent.toLowerCase();
  
      const tiposDispositivos = {
        mobile: "Phone/ S.O Android.",
        mobile: "(iPhone, iPad, iPod)/ S.O iOS.",
        windows: "Desktop/Windows.",
        macintosh: "Macintosh (Mac)/S.O macOS.",
        linux: "Desktop/S.O Linux.",
      };
  
      const dispositivoDetectado = Object.entries(tiposDispositivos).find(
        ([dispositivo, descripcion]) => {
          const patronRegex = new RegExp(dispositivo, "i");
          return patronRegex.test(agenteUsuario);
        }
      );
  
      if (dispositivoDetectado) {
        resolve(dispositivoDetectado[0]);
      } else {
        resolve("mobile");
      }
    });
  };

  // Funcion para coger el leadId//
export const getVisitorId = (time)=>{
    return new Promise((resolve, reject) => {
      setInterval(() => {
        if (globalThis.Piwik) {
          // @ts-ignore
          clearInterval()
          const idVisitor = globalThis.Piwik.getAsyncTracker().getVisitorId()
          resolve(idVisitor) 
        } else {
          reject(new Error('Piwik no existe '))
        }
      },time)
    })
  }
  
  
  
  // Funcion para medir la velocidad del internet
  export const velocityInternet = async ()=>{
    // @ts-ignore
    var conexion = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
    if (conexion && conexion.downlink) {
      var velocidad = conexion.downlink;
  
      // Aquí puedes definir la velocidad para considerar una conexión a 3G o menor
      var umbral3G = 2; // Mbps
  
      if (velocidad <= umbral3G) {
        return await getVisitorId(3000);
      } else {
        return await getVisitorId(2000);
      }
    }
  
  }


  
  // Función que ejecuta getLeadId después de un retraso de 3 milisegundos (3000 milisegundos)
  
  
  export const getLeadId = () => {
    return velocityInternet()
  }

/**
 * Funcion que comprueba que Digital Data este cargado
 * **/

export function waitForDigitalData() {
  return new Promise(async (resolve, reject) => {
    let counter = 0;
    var MAX_COUNTER = 100;

    const interval = setInterval(() => {
      counter++;
      if (
          typeof globalThis.digitalData === "object" && globalThis.digitalData
      ) {
        // @ts-ignore
        clearInterval(interval);
        resolve(globalThis.digitalData);
      } else if (counter > MAX_COUNTER) {
        // @ts-ignore
        clearInterval(interval);
        reject(new Error('Problemas en recogida Digital Data.'));
      }
    }, 400);
  })}