import { Globals } from "../../globals/variables";
import { getDevice, loadLib, loadLibD3AWhatsappButton, loadLibD3AWhatsappButtonMobile, loadLibTimeOut } from "../../utils/utils";
import { activarWhatsappD3A } from "./js/activate";


/**
 * @typedef {Object} configWhatsapp configuracion de estilos y diseño de widget/boton de whatsapp por defecto . Solo se activa cuando no hay una configuracion por cliente 
 */
export const configWhatsapp = () => {

  const config = {
    mode: "widget",
    phoneNumber: "34656968287",
    message: "¡Hola! Quiero asesorarme sobre tarifas de luz y gas. (Por favor, no elimines ni modifiques el siguiente id de solicitud. ¡Gracias!) ",
    widget: {
      tooltip: {
        showAfterTime: 0,
        showPermanent: false,
        temporalTime: 5000,
        message: "Contacta por WhatsApp",
      },
      styles: {
        backgroundColor: "rgb(37, 211, 102)",
        svgColor: "#FFF",
        tootipBackgroundColor: "#25D366",
        tooltipTextColor: "#FFF",
        pulseAnimation: "rgba(37, 211, 101, 0.8)",
        widgetHeight: "10",
        widgetBottom: "5",
        widgetRight: "1"
      },
    },
    button: {
      message: "Hablemos por WhatsApp",
      styles: {
        backgroundColor: "#009900",
        hoverBackgroundColor: "#009900",
        svgColor: "#FFF",
        svgWidth: "30px",
        textColor: "#FFF",
        width: "250px",
        height: "38px",
        borderRadius: "0.5rem",
        fontSize: "18px",
      },
    },
  };
  return btoa(JSON.stringify(config));
};



/**
 * Funcion que llama al endpoint de D3A para consultar si hay agentes disponibles o no 
 * @returns {boolean} devuelve true o false
 */
export const isAgentAvalaible = async () => {
  const myHeaders = new Headers();
  myHeaders.append("x-client-id", Globals.D3A.clientId);
  myHeaders.append("x-campaign-id", Globals.D3A.campaignId);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  const response = await fetch("https://d3a.walmeric.com/sio/api/v2/agents/available", requestOptions);
  return await response.json();
};






/**
 * Funcion que carga el scriot de whatsapp de D3A . Dicho script se activa siempre que haya agentes disponibles y la url este comtemplada en la funcion @function activarWhatsapp
 * Pertnece al test C
 * @returns {script} Script de whatsapp D3A
 */

export const loadWhatsapp = async () => {
 
  const agentAvalaible=await isAgentAvalaible() // Lo dejamos en pre mientras tanto 



    globalThis.clientId = Globals.D3A.clientId;
    globalThis.campaignId = Globals.D3A.campaignId;
    globalThis.idTagDelio = Globals.idTagD1C;
    globalThis.whatsappConfig = typeof globalThis.digitalData.whatsappConfig!="undefined" ?  globalThis.digitalData.whatsappConfig() :configWhatsapp();
    globalThis.phone = undefined;
    
    return loadLibTimeOut(
      "https://d3a.walmeric.com/whatsapp/bundle.js",
      Globals.idTagD1C
    );

}

/**
 * Funcion que activa la personalizacion A : por el momento no hace nada ya que no carga whatsapp
 * @returns 
 * 
 */
export const loadWhatsappTestA = async () => {
 
  return console.log("Activando Personalizacion tipo A")

}

/**
 * Funcion que activa la personalizacion B : 
 * @returns 
 * 
 */
export const loadWhatsappTestB = async () => {
 
  const agentAvalaible=await isAgentAvalaible() // Lo dejamos en pre mientras tanto 

  const device= await getDevice()

  globalThis.clientId = Globals.D3A.clientId;
  globalThis.campaignId = Globals.D3A.campaignId;
  globalThis.idTagDelio = Globals.idTagD1C;
  globalThis.whatsappConfig = typeof globalThis.digitalData.whatsappConfig!="undefined" ?  globalThis.digitalData.whatsappConfig() :configWhatsapp();
  globalThis.phone = undefined;
 
  if(device!="mobile"){
    return loadLibD3AWhatsappButton(
      "https://d3a.walmeric.com/whatsapp/bundle.js",
      Globals.idTagD1C
    );
  }else{
     loadLibD3AWhatsappButtonMobile("https://d3a.walmeric.com/whatsapp/bundle.js",
      Globals.idTagD1C)
  }
  



}

