/**
 * Funcion que setea un evento en el boton con la clase '.tab-link' solo si esta en esa @url https://ahorroendesa.com/formidable .De cumplirse 
 * introduce el valor proveniente del digital data el valor skill como etiqueta declarativa y actualiza las etiquetas.
 */
export function addClickEventToTabLinks() {
    if (window.location.href.startsWith('https://ahorroendesa.com/formidable')) {
      const tabLinks = document.querySelectorAll('.tab-link');
  
      tabLinks.forEach(function(tabLink) {
        tabLink.addEventListener('click', function() {
          if (typeof DFI.updateVarsInDelio === "function") {
            DFI.labels.EDeclaratives.skill = digitalData.skill;
            DFI.updateVarsInDelio();
          }
        });
      });
    }
  }
  
 