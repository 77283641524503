import { loadAdobePersonalization } from "../../../D3A/js/loadAdobePersonalization";
import { checkExpTarget } from "../checkExpTarget";
import { updateVarsInDelio } from "../updateVarsInDelio";
import { getDataVisit, updateVisitNew } from "../updateVisit";

/**
 * Funcion que pasandole como parametro un nombre , busca dicho nombre asociado a una cookie y devuelve un valor
 * @param {string} name nombre de la cookie 
 * @returns valor de la cookie 
 */
function getCookieValue(name) {
    
    try {
        let nameEQ = name + "=";
        let ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
          let c = ca[i].trim();
          if (c.indexOf(nameEQ) === 0) {
            // Usa decodeURIComponent para decodificar el valor de la cookie
            return decodeURIComponent(c.substring(nameEQ.length, c.length));
          }
        }
      } catch (error) {
        console.error("Error al obtener el valor de la cookie:", error);
      }
}

/**
 * Funcion que comprueba si una cookie existe 
 * @param {string} nameCookie 
 * @param {string} paramBuscar 
 * @returns {object} objecto de cookie analisis y publicidad
 */

export function explodeParamCookie(nameCookie, paramBuscar) {
    try {
        let queryString = JSON.parse(getCookieValue(nameCookie));
        if (!queryString) {
          throw new Error("La cookie no existe"); // Lanza un nuevo error si la cookie no tiene valor o no existe
        }
        
        return queryString;
      } catch (error) {
         return null;
      }
      
  }

  /**
   * Funcion que llama a @function getCookieValue para obtener el valor de la cookie . Comprueba que dicho valor este definido y setea su valor correspondiente
   * @param {string} name 
   * @returns 
   */
  
  export const storeCookiesacceptance = function (name) {
    let cookieAccepted = getCookieValue(name);

 
        let objCookiesAccepted = JSON.parse(cookieAccepted);
        digitalData["cookies_accepted_analisis"] = typeof objCookiesAccepted.analisis === 'string' ? objCookiesAccepted.analisis : "false";
        digitalData["cookies_accepted_publicidad"] = typeof objCookiesAccepted.publicidad === 'string' ? objCookiesAccepted.publicidad : "false";

    return typeof cookieAccepted === 'string';
}

/**
 * Es una función que ejecuta un observador , que observa el valor de la cookie cuando esta se define . Dependiendo del valor si se acepta o se rechazan cookies carga diferentes funciones 
 * @param {string} name nombre de la cookie  
 * @param {*} paramBuscar parametro de la cookie (no usado en este script)
 */
  
  export async function watchCookie(name, paramBuscar) {
    let lastCookieValue = explodeParamCookie(name, paramBuscar);
    let timeoutId = null; // ID del timeout para poder cancelarlo más tarde
    const startTime = Date.now(); // Tiempo de inicio para calcular el tiempo transcurrido
    const maxDuration = 1 * 60 * 1000; // Tiempo para parar el observador
  
    const checkCookieChange = async () => {
  
    

      // Verifica si han pasado X minutos desde que comenzó la observación
      if (Date.now() - startTime >= maxDuration) {
        console.log(`Se ha excedido el tiempo máximo de observación de la cookie "${name}".`);
        return; // Detiene la función si han pasado 5 minutos
      }
  
      let currentCookieValue = explodeParamCookie(name, paramBuscar);
  
      
      // Verificar si la condición específica se cumple para detener la observación
      let stopConditionMet = currentCookieValue;
      if (stopConditionMet) {
        console.log(`Usuario acepto la cookie "${name}" tiene el valor específico; deteniendo la observación `);
        //Acepta cookies  y cambiamos valor en digital_data
        loadAdobePersonalization()
        storeCookiesacceptance(name)
        checkExpTarget()
        updateVarsInDelio()
        updateVisitNew( await getDataVisit())
        

      } else {
        timeoutId = setTimeout(async function (){
          if (JSON.stringify(currentCookieValue) !== JSON.stringify(lastCookieValue)) {
            //callback(currentCookieValue);
            lastCookieValue = currentCookieValue;
  
            if (!stopConditionMet) {
              checkCookieChange(); // Continuar observando
              
            } else {
              console.log(`Usuario acepto la cookie "${name}" tiene el valor específico; deteniendo la observación `);
               loadAdobePersonalization()
             //Acepta cookies 
                //Acepta cookies  y cambiamos valor en digital_data
                storeCookiesacceptance(name)
                checkExpTarget()
                updateVarsInDelio()
                updateVisitNew(await getDataVisit())
            }
          } else {
            checkCookieChange(); // Vuelve a chequear después de un tiempo
          }
        }, 1000); // Intervalo de sondeo
      }
    };
  
    // Iniciar la observación de la cookie
    checkCookieChange();
  
    // Establecer un límite de tiempo para detener la observación después de 5 minutos
    setTimeout(async() => {
      clearTimeout(timeoutId);
      checkExpTarget()
      updateVarsInDelio()
      storeCookiesacceptance(name)
      updateVisitNew(await getDataVisit())
      console.log(`Se ha detenido la observación de la cookie "${name}" después de  minutos.`);
    }, maxDuration);
  }