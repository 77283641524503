import { Globals } from "../../../globals/variables"
import { getLeadId, velocityInternet, waitForDigitalData } from "../../../utils/utils"



export const getDataVisit= async ()=>{

    const data= await waitForDigitalData()
    globalThis.womtp = globalThis.womtp || {}
    globalThis.womtp.v_vars = globalThis.womtp.v_vars || []

    globalThis.womtp.v_vars[12] = globalThis.v_var_12 = data.variable_expTarget


    const updateVisitTo = [
        {
        id: 12,
        value: globalThis.v_var_12,
        }
    ]

    return updateVisitTo
}


/**
 * Función que llama al enpoint que actualiza las etiquetas de visita
 * @param {object} data - data de etiquetas de visita
 **/

export const updateVisitNew = async (data) => {
    const dataSend = data ? data : ''
    const idVisitor = await getLeadId()
  
    globalThis.DAL.Ajax({
      type: 'jsonp',
      url: 'https://ws.walmeric.com/provision/visitsLabel/updateVisitLabelByVisitorId.html',
      data: {
        format: 'jsonp',
        idTag: Globals.idTagD1C,
        idVisitor: idVisitor,
        data: JSON.stringify(dataSend),
      },
    })
  }