
/**
 * Función que setea el numero del telefono activo y refresca tanto las macarara , href como el numero de telefono a nivel html
 */
export function checkMask() {
    const interval = setInterval(() => {
        if (typeof globalThis.DFI !== "undefined" && typeof globalThis.DFI.telefonoRingPoolActivo !== "undefined") {
            const telefonoRingPoolActivo = globalThis.DFI.telefonoRingPoolActivo;
            if (telefonoRingPoolActivo.startsWith("900") || telefonoRingPoolActivo.startsWith("800")) {
                clearInterval(interval);
            } else {
                DRP.refreshService({
                    mask: "## ### ## ##",
                });
                clearInterval(interval);
            }
        }
    }, 1000);
}


