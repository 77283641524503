import { loadWhatsapp, loadWhatsappTestA, loadWhatsappTestB } from "../whatsapp"
import { activarWhatsappD3A } from "./activate"

/**
 * Funcion que activa la personalización de Whatsapp , reocogiendo los valores de Adobe pasados por Endesa . 
 * Dicha funcion recibe dos parametros del Digital Data activacionWhatsapp y tipoTestWhatsapp
 * Cuando no se aceptas cookies no cargamos esta funcion 
 */
export async function loadAdobePersonalization (){

    const setTimeAdobe=setTimeout(async()=>{
        const activacionWhatsappLocal=await activarWhatsappD3A()

        const activacionWhatsapp=typeof(globalThis.digitalData.activacionWhatsapp)!="undefined"?globalThis.digitalData.activacionWhatsapp:activacionWhatsappLocal.activacion
        const tipoTest=typeof(globalThis.digitalData.activacionWhatsapp)!="undefined"?globalThis.digitalData.tipoTestWhatsapp:activacionWhatsappLocal.test
    
      
        if(activacionWhatsapp){
            clearTimeout(setTimeAdobe)
            const CALLBACK_SCRIPT={
                "A":loadWhatsappTestA,
                "B":loadWhatsappTestB,
                "C":loadWhatsapp,
            }
        
        
        
            const load_script_whatsapp=CALLBACK_SCRIPT[tipoTest]()?CALLBACK_SCRIPT[tipoTest]:"NO HAY PERSONALIZACION EXISTENTE O ESTA SIENDO CARGADO EN LOCAL"
        
            load_script_whatsapp
    
        }

    },1200)
  
  
}


