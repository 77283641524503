/**
 * Funcion para activar Whatsapp en landings locales y pilotos de Walmeric o en un futuro Endesa 
 * @returns {object} objeto de configuracion
 */
export const activarWhatsappD3A = () => {
    return new Promise((resolve) => {
      const urlsWhatsapp = [
       
        {
          url: "http://localhost:9006/",
          activacion: true,
          test:"C"
        },
        {
          url: "https://t.womtp.com/slider/c/endesa/script/speed/dist/index.html",
          activacion: true,
          test:"C"
        },
      ];
  
      const currentUrl = window.location.href;
  
      const matchingUrlObject = urlsWhatsapp.find((urlObject) =>
        currentUrl.includes(urlObject.url)
      );
  
      if (matchingUrlObject) {
        resolve(matchingUrlObject);
      }
  
      resolve(false);
    });
  };
  