export function checkExpTarget() {
    let existexpTargetInDigitaldataCount = 0;
    let execExpTargetToDo = true;
    let existexpTargetInDigitaldata = null;
  
    function handleExpTarget() {
      clearInterval(existexpTargetInDigitaldata);
  
      if (typeof digitalData.expTarget === "string") {
        digitalData.variable_expTarget = digitalData.expTarget;
      } else {
        digitalData.variable_expTarget = typeof(sessionStorage.target_test)!="undefined"?sessionStorage.target_test :"No_asignada";
      }
  
      execExpTargetToDo = false;
  
      if (typeof DFI.updateVarsInDelio === "function") {
        DFI.updateVarsInDelio();
      }
    }
  
    function checkExpTargetInterval() {
      existexpTargetInDigitaldataCount += 1;
  
      if (location.hostname !== "localhost") {
        if (typeof digitalData.expTarget === "string" && execExpTargetToDo) {
          handleExpTarget();
        }
  
        if (existexpTargetInDigitaldataCount > 10 && execExpTargetToDo) {
          clearInterval(existexpTargetInDigitaldata);
          digitalData.variable_expTarget = typeof(sessionStorage.target_test)!="undefined"?sessionStorage.target_test :"No_asignada";
          execExpTargetToDo = false;
          if (typeof DFI.updateVarsInDelio === "function") {
            DFI.updateVarsInDelio();
          }
        }
      }
    }
  
    existexpTargetInDigitaldata = setInterval(checkExpTargetInterval, 350);
  }