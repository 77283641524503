
/**
 * Funcion que llama al endpoint @url https://ws.walmeric.com/provision/wsclient/sendLabelsRingPool.html y actualiza las etiquetas proveientes del ringpool
 */
export const updateVarsInDelio = function () {
    if (typeof DFI.setDataLayer === "function") {
        DFI.setDataLayer();

        if (statusDFI.setDeclaratives) {
            DFI.setDeclaratives();
        }

        if (typeof window.Chat_config === "object") {
            window.Chat_config.labels = { EDeclaratives: DFI.labels.EDeclaratives };
        }

        if (statusDFI.initRingpoolCompleted && typeof window.DFI.telefonoRingPoolActivo === "string") {
            if (
                typeof Piwik === "object" &&
                typeof Piwik.getAsyncTracker === "function" &&
                typeof Piwik.getAsyncTracker().getVisitorId === "function" &&
                Piwik.getAsyncTracker().getVisitorId().length > 20
            ) {
                window.DFI.labelsVarRingPool.EDeclaratives = DFI.labels.EDeclaratives;
                var wsSendLabelsDigitaldata = "https://ws.walmeric.com/provision/wsclient/sendLabelsRingPool.html";
                wsSendLabelsDigitaldata += "?idServ=" + window.DFI.servicioActivo1.serviceId;
                wsSendLabelsDigitaldata += "&idTag=" + window.DFI.DFI_configuration.idTagDelioClient;
                wsSendLabelsDigitaldata += "&idvisitor=" + DFI.getLeadId();
                wsSendLabelsDigitaldata += "&labels=" + JSON.stringify(window.DFI.labelsVarRingPool);

                DAL.Ajax({
                    type: "jsonp",
                    url: wsSendLabelsDigitaldata,
                }).done(function (value) {
                    console.log("Resuelto")
                });
            }
        }
    }
};